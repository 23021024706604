import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Services</h1>
      <ContactForm><div className={"titles inline"}><h3>Contact me</h3></div></ContactForm> 
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div style={{padding:"40px"}}>
        
        <p><b>ART CONSULTING</b></p><p>

          Our aim is to help our clients select and acquire art for their home or business in an enjoyable and informative way.</p><p>
        Selecting artworks requires time and needs to take into consideration interior design, decoration or outdoor landscaping.</p><p>
        We are happy to be consulted for any request - to decorate your house with art, to start an art collection, to assist with your current art collection, perhaps to obtain art valuations or organise art restoration. </p><p>
        In fact to assist with anything art related!</p>

         
      <br /><br />
  <p><b>FRAMING</b></p><p>

    We offer personalised advice for presentation of any art-works. No art work is too small, too big, too inexpensive or too personal to frame or display.
      </p><p> We pride ourselves in being able to meet any framing challenge and any medium.  </p><p>
    <li> fabric art (including embroidery or tapestry), velum, 3D art, works on paper, works on canvas, metal art, memorabilia, masks, & photography.</li>  
      <li> preservation and conservation of art-works using conservation framing techniques.</li>
      <li> custom made acrylic boxes are designed and made to suit each individual object..</li></p><p>

   

   
<br /><br />

  <b>ART RESTORATION.</b></p><p>
    You may not have noticed how dark and dull a beloved oil painting has become. Or perhaps noticed mould (foxing) has grown across a favourite watercolour.
  </p><p>  Restoring an art work is very rewarding. To see a painting re-surface from beneath dark varnish and discolouration is magical. To see works on paper come alive again once mould has been removed and with new conservation matting is a joy.
    </p><p>We offer art restoration for most art-works, including paintings, prints, glass or ceramic.</p><p>

   
<br /><br />
  <b>PICTURE HANGING</b></p>
    <p>You may have recently relocated. Or bought a new painting and need to rearrange your paintings. Perhaps you wish to refresh a space by re-hanging your artworks? You may wish to hang multiple paintings or photos or memorabilia in a stairwell or hallway? </p>
    <p>To minimise damage, we can install a picture hanging system that hangs art securely to the wall. You can adjust paintings flexibly at any time without drilling holes into walls.</p>
    <p>However, if an art-work or mirror is too heavy for a hanging system, we pride ourselves on being able to hang pieces onto any surface, neatly, securely and professionally.</p></div>, span:"12"}] } />
<Carousel duration={10} images={["bc0d941d-1855-460b-aff3-a54b43173b8f.jpeg"]}/>
    </div>

  </Layout>

</div>
)

export default IndexPage
